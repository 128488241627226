import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import CTA from "../components/cta"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UseCases from "../components/UseCases"
import Card from "../components/Card"

import {
  Icon3dCubeSphere,
  IconAccessPoint,
  IconCode,
  IconShield,
  IconTable,
  IconTree,
} from "@tabler/icons"
import { graphql } from "gatsby"
import HeadlessCTA from "../components/headlessCTA"

const dataPoints = [
  {
    key: 1,
    title: "Firmographics",
    preview:
      "Google companies at scale, in real time, to extract key firmographic data. The freshness increases data quality, and Googling increases match rate/coverage.",
    url: null,
    customisable: false,
    examples: [
      "Company description",
      "Founded",
      "Industry",
      "Operating Locations",
      "Brand Size",
      "Headcount",
      "Headquarters",
      "Company Type (Public/Private)",
      "Stock Ticker",
      "Revenue",
    ],
  },
  {
    key: "custom-industries",
    title: "Custom Industries",
    preview:
      "Use AI to read company websites, and categorise them into industries and verticals of your choosing. Very useful if you have custom hierachies and sub-industry dropdowns in your CRM.",
    url: null,
    customisable: true,
    examples: [
      "Fintech",
      "Insurtech",
      "B2B",
      "SaaS",
      "Ecommerce (has checkout)",
      "NFT marketplace",
      "Gig economy marketplace",
      "and more",
    ],
  },
  {
    key: 2,
    title: "Technographics",
    preview:
      "Software and technology usage sourced from website source code, case studies, job postings and more. Customise this data point by putting in a list of technologies you are interested in.",
    url: null,
    customisable: true,
    examples: [
      "CRMs",
      "ERPs",
      "eCommerce platform (Shopify etc.)",
      "Sales tools (Gong/Clari etc.)",
      "Front-end framework (React/Vue..)",
      "Ad Channels (Google Ads/Facebook Ads..)",
    ],
  },
  {
    key: "social",
    title: "Social Followers",
    preview:
      "Follower count and profile URLs from leading social networks, like Twitter, LinkedIn, Instagram and TikTok.",
    url: null,
    customisable: false,
  },
  {
    key: 4,
    title: "Business Model and Pricing",
    preview:
      "Tap into company websites, pricing pages (for SaaS) and product pages (for eCommerce stores) to understand pricing and business model",
    url: null,
    customisable: false,
    examples: [
      "Has volume based pricing?",
      "Enterprise tier?",
      "Monthly/Annual pricing?",
      "Minimum/Maxmium price",
      "Average Product Pricing",
      "Most Common Currency",
    ],
  },
  {
    key: 4,
    title: "Hiring, remote and jobs",
    preview:
      "Apply large language models (LLMs) onto job post data from 3 sources: company job boards, job aggregators, and job posts found online. You can specify job titles or departments, and filter by hiring region.",
    url: null,
    customisable: true,
    examples: [
      "Remote first?",
      "Open remote roles?",
      "Remote hiring countries",
      "Engineering open roles in Canada",
      "Sales oepn roles in UK",
      "Sales open roles everywhere",
    ],
  },
  {
    key: 5,
    title: "Funding",
    preview:
      "We source funding data from filings and news releases, much like popular sources of funding data.",
    url: null,
    examples: [
      "Most recent funding date",
      "Funding stage",
      "Lead investor",
      "Amount raised",
      "Funding round currency",
    ],
  },
  {
    key: 6,
    title: "Team sizes",
    preview:
      "Using professional social network data, you can look at how many people have a specific job title (e.g. DevOps) in a company, or how large are specific teams (e.g. Engineering)",
    url: null,
    customisable: true,
    examples: [
      "Sales team size",
      "Legal team size",
      "# of software engineers",
      "# of product managers",
    ],
  },
  {
    key: 7,
    title: "Team locations",
    preview:
      "Using professional social network data, you can look at how many people work in different locations in any company.",
    url: null,
    customisable: true,
    examples: [
      "Headcount: United States",
      "Headcount: United Kingdom",
      "Headcount: Canada",
      "Headcount: Germany",
    ],
  },
  {
    key: 77,
    title: "Growth",
    preview:
      "Headcount growth: 3 months, 6 months, 12 months. Filter by team and location. Executive hires from the last 3 months.",
    url: null,
    customisable: true,
    examples: [
      "Growth of sales team last 3 months",
      "Growth of engineering team last 6 months",
      "Overall headcount growth last 12 months",
      "Growth of HR team last 6 months",
    ],
  },
  {
    key: 8,
    title: "Payments",
    preview:
      "What payment service providers and payment methods do companies accept? In what currencies?",
    url: null,
    customisable: true,
    examples: [
      "Currencies accepted",
      "Stripe/Adyen",
      "Klarna",
      "Visa/MasterCard",
      "Direct Debit",
      "Bank Transfer",
      "Cheques",
    ],
  },
  {
    key: 9,
    title: "Operating locations",
    preview: "Where does the company have offices, operate, and ship to?",
    url: null,
    customisable: true,
  },
  {
    key: 10,
    title: "Company Website",
    preview:
      "Find vision, mission, strategy statements. Engineering and sales blogs. Track keywords in terms and conditiions. Understand their sustainability stance, key benefits offered, and company culture.",
    url: null,
    customisable: true,
  },
  {
    key: 11,
    title: "Annual Reports",
    preview:
      "Mine key initiatives, risks and challenges. Replace repetitive Ctrl + F.",
    url: null,
    customisable: true,
  },
]

function PostCard({
  title,
  blurb,
  url,
  team,
  examples,
  account = true,
  customisable = false,
  type = "dataPoint",
}) {
  return (
    <Link
      id={title}
      to={url}
      className="group bg-white hover:bg-soft-tones-lilac-10 p-6 transition-all"
    >
      <div className="flex flex-col lg:flex-row items-start gap-4">
        {type === "spreadsheet" && (
          <IconTable className="text-soft-tones-lilac" />
        )}
        <div className="flex-1">
          <h2 className="lg:text-2xl font-semibold text-lilac mb-2">{title}</h2>
          <p className="text-dark-grey max-w-2xl">{blurb}</p>
          {examples && (
            <ul className="grid grid-cols-2 text-xs font-sans mt-4 list-disc marker:text-blue list-inside">
              {examples.map(e => (
                <li>{e}</li>
              ))}
            </ul>
          )}
        </div>
        {account && (
          <div className="text-xs border border-medium-grey rounded px-1">
            Account Attribute
          </div>
        )}
        {customisable && (
          <div className="text- text-xs border bg-soft-tones-pink border-purple-400 rounded px-1">
            Customisable
          </div>
        )}
      </div>
    </Link>
  )
}

const UseCaseListing = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes
  return (
    <Layout variant="plain">
      <SEO
        title="Data Marketplace"
        description="Unlimited selection of data at scale, get high fit accounts to matching your ideal customer criteria exactly."
      />
      <section className="text-white py-6 md:py-8 xl:py-12 bg-gradient-to-b from-[#111] to-slate-700">
        <div className="flex flex-col gap-6 px-4 container mx-auto">
          <h1
            className="font-extrabold text-4xl md:text-5xl xl:text-7xl text-white/50"
            style={{
              fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
            }}
          >
            Data Marketplace
          </h1>
          <h2 className="text-white max-w-3xl">
            Find new signals that predict customer fit, intent and sales
            potential, so that you can prioritise your entire target market and
            increase revenue efficiency.
          </h2>
          <HeadlessCTA />
        </div>
      </section>

      <main className="bg-light-grey">
        <div className="mx-auto container flex flex-col-reverse lg:flex-row">
          <div className="w-full lg:w-[340px] p-6 self-start sticky top-0">
            <div className="flex gap-2 items-center my-4 font-semibold">
              <IconAccessPoint size={16} className="text-dark-grey" />
              <span>Data Points</span>
            </div>
            <div className="text-sm text-dark-grey">
              <ul className="flex flex-col gap-1">
                {dataPoints.map(dp => (
                  <li>
                    <a
                      className="hover:text-lilac transition"
                      href={`#${dp.title}`}
                    >
                      {dp.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="border-l px-4 md:px-6 xl:px-12 pb-24 flex-1">
            <div className="py-8 text-dark-grey">Hot Topics</div>
            <div className="grid md:grid-cols-3 gap-2 xl:gap-6 auto-rows-[1fr]">
              <Card
                title="Digital @ Global 2000"
                icon={<Icon3dCubeSphere />}
                color="sky"
                link="/datasets/global-2000-digital-transformation/"
                blurb="Exploring digital transformation initiatives, AI/ML investments, and RPA."
              />
              <Card
                title="Sustainability @ Global 2000"
                icon={<IconTree />}
                color="emerald"
                link="/datasets/global-2000-sustainability/"
                blurb="Top sustainability stories around net zero targets, ESG initiatives."
              />
              <Card
                title="Cybersecurity @ Global 2000"
                icon={<IconShield />}
                color="rose"
                link="/datasets/global-2000-cyber-security/"
                blurb="Cyber security for initiatives, attacks, types (IoT, OT, zero trust)."
              />
            </div>
            <div className="py-8 text-dark-grey">Datasets</div>
            <div
              className="container flex flex-col gap-[1px] border border-medium-grey bg-medium-grey 
            rounded-lg overflow-hidden"
            >
              <PostCard
                key="top-50-plg"
                title="Top 50 Product Led Growth (PLG) companies in Europe"
                blurb="One of our customers used Tactic to build a list of 500+ venture-backed
            product-led growth companies in Europe. It took them just a few clicks."
                url="/top-50-PLG-companies-in-europe"
                account={false}
                type="spreadsheet"
              />
              <PostCard
                key="45-uk-b2b-saas"
                title="45 Fast Growth B2B SaaS companies in the United Kingdom"
                blurb="Across our customer base, everybody loves to target fast-growing B2B software companies. Here's a list of 45 such companies in the UK."
                url="/datasets/45-uk-b2b-saas"
                account={false}
                type="spreadsheet"
              />
              <PostCard
                key="global-2000-ai"
                title="Global 2000 companies investing in AI"
                blurb="Marketers love to innovate, and every year there are new ways to make marketing teams perform better. We looked at 5,000 large US companies to find out how they are doing."
                url="/datasets/global-2000-ai"
                account={false}
                type="spreadsheet"
              />
              <PostCard
                key="150-european-enterprise-sales-teams"
                title="150 large enterprise sales teams in Europe"
                blurb="Which venture backed companies have the largest and most sophisticated enterprise sales teams? We asked Tactic to find out. Do they sell to enterprise, mid-market, SMB, or all three?"
                url="/150-european-enterprise-sales-teams"
                account={false}
                type="spreadsheet"
              />
              <PostCard
                key="us-marketing-channels-analysis"
                title="How are large US marketing teams organised?"
                blurb="Marketers love to innovate, and every year there are new ways to make marketing teams perform better. We looked at 5,000 large US companies to find out how they are doing."
                url="/datasets/us-marketing-channels-analysis"
                account={false}
                type="spreadsheet"
              />
            </div>
            <div className="py-8 text-dark-grey" id="data-points">
              Data Points
            </div>
            <div
              className="container flex flex-col gap-[1px] border border-medium-grey bg-medium-grey 
            rounded-lg overflow-hidden"
            >
              {dataPoints.map(dp => (
                <PostCard
                  key={dp.id}
                  title={dp.title}
                  blurb={dp.preview}
                  url={dp.path}
                  customisable={dp.customisable}
                  examples={dp.examples}
                />
              ))}
              {posts.map(post => (
                <PostCard
                  key={post.id}
                  title={post.frontmatter.title}
                  blurb={post.frontmatter.preview}
                  url={post.path}
                  team={post.frontmatter.team}
                />
              ))}
            </div>
          </div>
        </div>
      </main>
      <UseCases />
      <CTA />
    </Layout>
  )
}

UseCaseListing.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "use-case" } } }
    ) {
      nodes {
        id
        frontmatter {
          author
          title
          date
          preview
          team
        }
        path: gatsbyPath(
          filePath: "/{MarkdownRemark.frontmatter__category}/{MarkdownRemark.frontmatter__slug}"
        )
      }
    }
  }
`

export default UseCaseListing
