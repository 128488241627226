import { Link } from "gatsby"
import React from "react"

const Card = ({ title, link, icon, color, blurb }) => (
  <Link to={link}>
    <div
      className="bg-gradient-to-tr from-white to-light-grey rounded-lg border border-medium-grey p-6 h-full
      flex flex-col gap-4 hover:border-lilac hover:to-soft-tones-lilac-10 hover:shadow-xl transition duration-500 group"
    >
      <span className="bg-sky-100 border-sky-400 text-sky-400 hidden"></span>
      <span className="bg-emerald-100 border-emerald-400 text-emerald-400 hidden"></span>
      <span className="bg-rose-100 border-rose-400 text-rose-400 hidden"></span>

      <div
        className={`rounded-full bg-${color}-100 border border-${color}-400 text-${color}-400 h-10 w-10 flex items-center justify-center`}
      >
        {icon}
      </div>
      <span className="md:text-xl group-hover:text-lilac transition duration-500">
        {title}
      </span>
      <div className="text-xs font-sans text-dark-grey">{blurb}</div>
    </div>
  </Link>
)

export default Card
